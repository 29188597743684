import TreeBuilder from './builder';
import * as _ from 'lodash';
import * as d3 from 'd3';

export const dTree = {

    init: function (data, options = {}) {

        const opts = _.defaultsDeep(options || {}, {
            target: '#graph',
            debug: false,
            width: 600,
            height: 600,
            callbacks: {
                nodeClick: function (name, extra, id) { },
                nodeRenderer: function (name, x, y, height, width, extra, id, nodeClass, textClass, textRenderer) {
                    return TreeBuilder._nodeRenderer(name, x, y, height, width, extra, id, nodeClass, textClass, textRenderer);
                },
                nodeSize: function (nodes, width, textRenderer) {
                    return TreeBuilder._nodeSize(nodes, width, textRenderer);
                },
                nodeSorter: function (aName, aExtra, bName, bExtra) {
                    return 0;
                },
                textRenderer: function (name, extra, textClass) {
                    return TreeBuilder._textRenderer(name, extra, textClass);
                },
            },
            margin: {
                top: 0,
                right: 0,
                bottom: 0,
                left: 0
            },
            nodeWidth: 100,
            styles: {
                node: 'node',
                linage: 'linage',
                marriage: 'marriage',
                text: 'nodeText'
            }
        });

        data = this._preprocess(data, opts);
        const treeBuilder = new TreeBuilder(data.root, data.siblings, opts);
        treeBuilder.create();

    },

    _preprocess: function (data, opts) {

        const siblings = [];
        let id = 0;

        const root = {
            name: '',
            id: id++,
            hidden: true,
            children: []
        };

        const reconstructTree = function (person, parent) {

            // convert to person to d3 node
            const node = {
                name: person.name,
                id: id++,
                hidden: false,
                children: [],
                extra: person.extra,
                textClass: person.textClass ? person.textClass : opts.styles.text,
                class: person.class ? person.class : opts.styles.node,
                noParent: false
            };

            // hide linages to the hidden root node
            if (parent === root) {
                node.noParent = true;
            }

            // apply depth offset
            for (let i = 0; i < person.depthOffset; i++) {
                const pushNode = {
                    name: '',
                    id: id++,
                    hidden: true,
                    children: [],
                    noParent: node.noParent
                };
                parent.children.push(pushNode);
                parent = pushNode;
            }

            // sort children
            dTree._sortPersons(person.children, opts);

            // add "direct" children
            _.forEach(person.children, function (child) {
                reconstructTree(child, node);
            });

            if (!parent.hasOwnProperty('children')) {
                parent.children = [];
            }
            parent.children.push(node);

            // sort marriages
            dTree._sortMarriages(person.marriages, opts);

            // go through marriage
            _.forEach(person.marriages, function (marriage, index) {

                if (marriage.spouse.name !== 'Child Tags') {


                    const m = {
                        name: '',
                        id: id++,
                        hidden: true,
                        noParent: true,
                        children: [],
                        extra: marriage.extra
                    };

                    const sp = marriage.spouse;

                    const spouse = {
                        name: sp.name,
                        id: id++,
                        hidden: false,
                        noParent: true,
                        children: [],
                        textClass: sp.textClass ? sp.textClass : opts.styles.text,
                        class: sp.class ? sp.class : opts.styles.node,
                        extra: sp.extra,
                        marriageNode: m
                    };

                    parent.children.push(m, spouse);

                    dTree._sortPersons(marriage.children, opts);
                    _.forEach(marriage.children, function (child) {
                        reconstructTree(child, m);
                    });

                    siblings.push({
                        source: {
                            id: node.id
                        },
                        target: {
                            id: spouse.id
                        },
                        number: index
                    });

                } else {

                    dTree._sortPersons(marriage.children, opts);
                    _.forEach(marriage.children, function (child) {
                        reconstructTree(child, node);
                    });
                }
            });

        };

        _.forEach(data, function (person) {
            reconstructTree(person, root);
        });

        return {
            root: d3.hierarchy(root),
            siblings: siblings
        };

    },

    _sortPersons: function (persons, opts) {
        if (persons !== undefined) {
            persons.sort(function (a, b) {
                return opts.callbacks.nodeSorter(a.name, a.extra, b.name, b.extra);
            });
        }
        return persons;
    },

    _sortMarriages: function (marriages, opts) {
        if (marriages !== undefined && Array.isArray(marriages)) {
            marriages.sort(function (marriageA, marriageB) {
                const a = marriageA.spouse;
                const b = marriageB.spouse;
                return opts.callbacks.nodeSorter(a.name, a.extra, b.name, b.extra);
            });
        }
        return marriages;
    }

};

export default dTree;
