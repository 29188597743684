import { Component, OnInit } from '@angular/core';
import { ConfigurationService } from '../../services/configuration.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  public siteCopyright: string;
  public currentYear: string;

  constructor(private configurationService: ConfigurationService) { }

  ngOnInit() {
    this.siteCopyright = this.configurationService.siteCopyright;
    this.currentYear = String((new Date()).getFullYear());
  }

}
