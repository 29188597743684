import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ConfigurationService } from '../services/configuration.service';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit, AfterViewInit {

    public loaded = false;
    constructor(private configurationService: ConfigurationService) { }

    ngOnInit() {
      this.configurationService.setTitle('Terms of use');
    }

    ngAfterViewInit() {
        this.loaded = true;
    }
}
