import { Component, OnInit } from '@angular/core';
import { ConfigurationService } from '../../services/configuration.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  public siteName: string;

  constructor(private configurationService: ConfigurationService) { }

  ngOnInit() {
    this.siteName = this.configurationService.siteName;
  }

}
