import { Collection } from './../models/Collection';
import { ConfigurationService } from './../services/configuration.service';
import { APIService } from './../services/api.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Tag } from '../models/Tag';

@Component({
  selector: 'app-expo',
  templateUrl: './expo.component.html',
  styleUrls: ['./expo.component.scss']
})
export class ExpoComponent implements OnInit {
    public tag: Tag = new Tag();
    public collections: Collection[] = [];
  constructor(
    private configurationService: ConfigurationService,
    private apiService: APIService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
        this.configurationService.setTitle('Explore ' + params.tag_title);

        this.apiService.getTagsBasic().subscribe((tags: Tag[]) => {
            this.tag = this.apiService.getTagByTerm(params.tag_title, params.tag_title, tags);
            this.apiService.getTagCollections(this.tag.id).subscribe((collections: Collection[]) => {
                this.collections = collections;
            });
        });
    });
  }

}
