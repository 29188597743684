import { AcknowledgementsComponent } from './../acknowledgements/acknowledgements.component';
import { TermsComponent } from './../terms/terms.component';
import { ContactComponent } from './../contact/contact.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SearchComponent } from '../search/search.component';
import { ResultsComponent } from '../results/results.component';
import { InfoComponent } from '../info/info.component';
import { MapComponent } from '../map/map.component';
import { SplashComponent } from '../splash/splash.component';
import { ExpoComponent } from '../expo/expo.component';
import { CollectionComponent } from '../collection/collection.component';
import { HelpComponent } from '../help/help.component';
import { GeneologyComponent } from '../geneology/geneology.component';

const routes: Routes = [
    {
        path: 'collection/:collection_id',
        component: CollectionComponent,
    },
    {
        path: 'expo/:tag_title',
        component: ExpoComponent,
    },
    {
        path: '',
        component: SplashComponent,
    },
    {
        path: 'about',
        component: InfoComponent,
    },
    {
        path: 'results/:tag_ids',
        component: ResultsComponent,
    },
    {
        path: 'map',
        component: MapComponent,
    },
    {
        path: 'map/geo_tags/:geo_tags',
        component: MapComponent,
    },
    {
        path: 'map/map_layers/:map_layers',
        component: MapComponent,
    },
    {
        path: 'info',
        component: InfoComponent,
    },
    {
        path: 'help',
        component: HelpComponent,
    },
    {
        path: 'geneology',
        component: GeneologyComponent,
    },
    {
        path: 'search',
        component: SearchComponent,
    },
    {
        path: 'acknowledgements',
        component: AcknowledgementsComponent,
    },
    {
        path: 'terms',
        component: TermsComponent,
    },
    {
        path: 'contact',
        component: ContactComponent,
    },
    {
        path: '**',
        component: SplashComponent,
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { useHash: true })
    ],
    exports: [
        RouterModule
    ],
    declarations: []
})
export class RoutingModule { }
