import { Component, OnInit } from '@angular/core';
import { ConfigurationService } from '../services/configuration.service';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit {

    constructor(private configurationService: ConfigurationService) { }

    ngOnInit() {
      this.configurationService.setTitle('Help');
    }

}
