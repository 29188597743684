import { Tag } from './Tag';
import { GeoTag } from './GeoTag';
import { ResourceType } from './ResourceType';

export class Resource {
    id: number;
    title: string;
    url: string;
    description: string;
    created: Date;
    modified: Date;
    type: ResourceType;
    related: string[];
    tags: Array<Tag>;
    geoTags: Array<GeoTag>;
    creator: string;
    download: string;
    thumbnail_url: string;
}
