
import {map} from 'rxjs/operators';
import { Observable} from 'rxjs/Observable';
import { Injectable } from '@angular/core';
import { Tag } from '../models/Tag';
import { ConfigurationService } from './configuration.service';
import { HttpClient } from '@angular/common/http';
import { GeoTag } from '../models/GeoTag';
import { Resource } from '../models/Resource';
import { ResourceType } from '../models/ResourceType';
import { Collection } from '../models/Collection';
import { of } from 'rxjs';

@Injectable()
export class APIService {
    private api_url: string;
    private tags: Tag[] = [];
    private geo_tags: GeoTag[] = [];
    private resources: Resource[] = [];

    constructor(
        private configurationService: ConfigurationService,
        private http: HttpClient
    ) {
        this.api_url = this.configurationService.api_url;
    }

    getTagCollections(tag_id: number): Observable<Collection[]> {
        return this.http.get(this.api_url + '/api/tag/' + tag_id + '/collections').pipe(map((response: Tag) => {
            return response.children;
        }, (error) => {
            console.log('API Error:', error);
        }));
    }

    getTagResources(tag_id: number): Observable<Resource[]> {
        return this.http.get(this.api_url + '/api/tag/' + tag_id + '/resources').pipe(map((response: Tag) => {
            return response.resources;
        }, (error) => {
            console.log('API Error:', error);
        }));
    }

    // this function is terrible and needs work
    getGeoTag(tag_id: number): Observable<GeoTag> {
        return Observable.create(obs => {
                this.http.get(this.api_url + '/api/geo_tags/' + tag_id).pipe(map((response: GeoTag) => {
                    let resource_count = response.resources.length;
                const hydrated_resources = [];
                response.resources.forEach(shadow_resource => {
                    this.getResource(shadow_resource.id).subscribe(returned_resource => {
                        resource_count--;
                        if (resource_count < 1) {
                            hydrated_resources.push(returned_resource);
                            response.resources = hydrated_resources;
                            obs.next(response);
                        } else {
                            hydrated_resources.push(returned_resource);
                        }
                    });
                });
            }, (error) => {
                console.log('API Error:', error);
            })).subscribe();
        });
    }

    getResource(resource_id: number): Observable<Resource> {
        return this.http.get(this.api_url + '/api/resources/' + resource_id).pipe(map((response: Resource) => {
            return response;
        }, (error) => {
            console.log('API Error:', error);
        }));
    }

    getTagsBasic(): Observable<Tag[]> {
        if (this.tags.length < 1) {
            return this.http.get(this.api_url + '/api/tags/basic').pipe(map((response: Tag[]) => {
                this.tags = response;
                return this.tags;
            }, (error) => {
                console.log('API Error:', error);
            }));
        } else {
            return of(this.tags);
        }
    }

    getGeoTagsBasic(): Observable<GeoTag[]> {
        if (this.geo_tags.length < 1) {
            return this.http.get(this.api_url + '/api/geo_tags/basic').pipe(map((response: GeoTag[]) => {
                this.geo_tags = response;
                return this.geo_tags;
            }, (error) => {
                console.log('API Error:', error);
            }));
        } else {
            return of(this.geo_tags);
        }
    }

    getResourcesBasic(): Observable<Resource[]> {
        if (this.resources.length < 1) {
            return this.http.get(this.api_url + '/api/resources/basic').pipe(map((response: Resource[]) => {
                this.resources = response;
                return response;
            }, (error) => {
                console.log('API Error:', error);
            }));
        } else {
            return of(this.resources);
        }
    }

    getCollection(collection_id: number): Observable<Collection> {
        return this.http.get(this.api_url + '/api/collections/' + collection_id).pipe(map((response: Collection) => {
            return response;
        }, (error) => {
            console.log('API Error:', error);
        }));
    }

    getTagByTerm(term: string, context: string, tagArr: Tag[]): Tag {
        return tagArr.filter((item: Tag) => {
            return (term.toLowerCase() === item.title.toLowerCase() && context.toLowerCase() === item.context.toLowerCase());
        })[0] || new Tag();
    }

    getTagById(tag_id: number, tagArr: Tag[]): Tag {
        return tagArr.filter((item: Tag) => {
            return (tag_id === item.id);
        })[0] || new Tag();
    }

    getGeoTagById(tag_id: number, tagArr: GeoTag[]): GeoTag {
        return tagArr.filter((item: GeoTag) => {
            return (tag_id === item.id);
        })[0] || new GeoTag();
    }

    getResourceById(tag_id: number, resourceArr: Resource[]): Resource {
        return resourceArr.filter((item: Resource) => {
            return (tag_id === item.id);
        })[0] || new Resource();
    }

    filterResourcesByType(type_term: string, resources: Resource[]): Array<Resource> {
        return resources.filter((resource: Resource) => {
            return (resource.type.title === type_term);
        });
    }

    getResourceTypes(resources: Resource[]): ResourceType[] {
        // get all type objects, cast them as json for the comparison then filter only the unique ones
        return resources.map((resource: Resource) => {
            return JSON.stringify(resource.type);
        }).filter((resource, index, array) => {
            return array.indexOf(resource) === index;
        }).map((json_string: string) => {
            return JSON.parse(json_string);
        });

    }

    getGeneology(): Observable<any> {
        return this.http.get(this.api_url + '/utilities/geneology').pipe(map((response: any) => {
            return response;
        }, (error) => {
            console.log('API Error:', error);
        }));
    }
}
