import { Resource } from './Resource';

export class GeoTag {
    id: number;
    title: string;
    description: string;
    longitude: number;
    latitude: number;
    created: Date;
    modified: Date;
    resources: Resource[];
    thumbnail_url: string;
}
