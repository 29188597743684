import { Component, OnInit, ElementRef, ViewChild, AfterViewInit, HostListener } from '@angular/core';

import OlMap from 'ol/Map';
import OlXYZ from 'ol/source/XYZ';
import OlTileLayer from 'ol/layer/Tile';
import OlVectorLayer from 'ol/layer/Vector';
import OlImageLayer from 'ol/layer/Image';
import OlVectorSource from 'ol/source/Vector';
import OlLayerGroup from 'ol/layer/Group';
import OlView from 'ol/View';
import Point from 'ol/geom/Point';
import Feature from 'ol/Feature';
import Icon from 'ol/style/Icon';
import Style from 'ol/style/Style';
import Zoom from 'ol/control/Zoom';
import ImageWMS from 'ol/source/ImageWMS';
import { ConfigurationService } from '../services/configuration.service';
import { Resource } from '../models/Resource';
import { APIService } from '../services/api.service';
import { GeoTag } from '../models/GeoTag';
import { LayerService } from '../services/layer.service';
import { ActivatedRoute } from '@angular/router';
import { fromLonLat, transform } from 'ol/proj';
import { ResourceType } from '../models/ResourceType';
import { NgxSmartModalService } from 'ngx-smart-modal';

@Component({
    selector: 'app-map',
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.scss']
})


export class MapComponent implements OnInit, AfterViewInit {

    @ViewChild('spatialMap') mapElement: ElementRef;

    private resizeEvent = window.document.createEvent('UIEvents');
    public map: OlMap;
    private source: OlXYZ;
    private mapLayer: OlTileLayer;
    private markerLayer: OlVectorLayer;
    private view: OlView;
    private markerSource = new OlVectorSource();


    public geoTags: Array<GeoTag> = [];
    public resources: Array<Resource> = [];


    public enabledGeoTags: Array<number> = [];
    public enabledLayers: Array<number> = [];

    public selectedGeoTag: GeoTag;


    constructor(
        private configurationService: ConfigurationService,
        private apiService: APIService,
        private layerService: LayerService,
        private route: ActivatedRoute,
        private ngxSmartModalService: NgxSmartModalService
    ) {
        this.resizeEvent.initUIEvent('resize', true, false, window, 0);
        window.dispatchEvent(this.resizeEvent);
    }

    public viewerJSUrlCast(url: string): string {
        return url.replace(this.configurationService.api_url, this.configurationService.api_url + '/ViewerJS/index.html#');
    }

    public viewerMicrosfotCast(url: string): string {
        return 'https://view.officeapps.live.com/op/view.aspx?src=' + encodeURIComponent(url);
    }

    public openModal(resource: Resource): void {
        this.ngxSmartModalService.setModalData(resource, 'resourceModal');
        this.ngxSmartModalService.getModal('resourceModal').open();
    }

    public clearModelData() {
        this.ngxSmartModalService.resetModalData('resourceModal');
    }

    public filterGeoTagResultsByType(type: string): Resource[] {
        return this.apiService.filterResourcesByType(type, this.selectedGeoTag.resources);
    }

    public getGeoTagResourceTypes(): ResourceType[] {
        return this.apiService.getResourceTypes(this.selectedGeoTag.resources);
    }

    public filterResultsByType(typeTitle: string): Array<Resource> {

        return this.resources.filter((resource) => {
            return resource.type.title === typeTitle;
        });
    }

    getTagLocalities(): Array<string> {
        const localityArr = [];
        this.geoTags.forEach((tag) => {
            const tagLocal = tag.title.split(', ').pop();
            if (localityArr.indexOf(tagLocal) < 0) {
                localityArr.push(tagLocal);
            }
        });
        return localityArr;
    }

    public filterGeoTagByLocality(locality: string): Array<GeoTag> {

        return this.geoTags.filter((tag) => {
            const tagLocal = tag.title.split(', ').pop();
            return tagLocal === locality;
        });
    }

    ngOnInit() {

        this.configurationService.setTitle('Explore');

        this.source = new OlXYZ({
            url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}'
        });

        this.mapLayer = new OlTileLayer({
            source: this.source
        });

        this.view = new OlView({
            center: fromLonLat([176.16667, -37.68611]), // has to be reversed for some obseen reason
            zoom: 12,
        });

        const markerStyle = new Style({
            image: new Icon(({
                anchor: [0.5, 46],
                anchorXUnits: 'fraction',
                anchorYUnits: 'pixels',
                opacity: 0.75,
                src: '/assets/images/map-pin.png'
            }))
        });

        this.markerLayer = new OlVectorLayer({
            source: this.markerSource,
            style: function (el, resolution) {
                console.log(el, el.getProperties().geoTag);
                return new Style({
                    image: new Icon(({
                        opacity: 1,
                        src: el.getProperties().geoTag.thumbnail_url,
                        scale: 0.1
                    }))
                });
            }
        });

        this.map = new OlMap({
            target: this.mapElement.nativeElement,
            layers: [
                this.mapLayer,
                this.markerLayer
            ],
            view: this.view,
            renderer: 'canvas',
            controls: [
                new Zoom()
            ],
            interaction: []
        });

        this.map.on('click', (e) => {
            const feature = this.map.getFeaturesAtPixel(e.pixel);
            if (feature !== null) {
                this.apiService.getGeoTag(feature[0].get('geoTag').id).subscribe(full_geoTag => {
                    this.selectedGeoTag = full_geoTag;
                });
                e.preventDefault();
            } else {
                this.selectedGeoTag = undefined;
            }
            this.resizeEvent.initUIEvent('resize', true, false, window, 0);
            window.dispatchEvent(this.resizeEvent);
        });

        this.resizeEvent.initUIEvent('resize', true, false, window, 0);
        window.dispatchEvent(this.resizeEvent);
    }

    activateMapLayer(resource_id: number): void {
        const locationInExistingArr = this.enabledLayers.indexOf(resource_id);
        if (locationInExistingArr > -1) {
            this.enabledLayers.splice(locationInExistingArr, 1);
        } else {
            this.enabledLayers.push(resource_id);
        }

        const mapLayers = [
            this.mapLayer,
            this.markerLayer
        ];
        this.enabledLayers.forEach((resource_arr_id) => {
            const resource: Resource = this.apiService.getResourceById(Number(resource_arr_id), this.resources);
            const newLayer = new OlImageLayer({
                source: new ImageWMS({
                    url: 'https://geoserver.epiphron.co.nz/wms',
                    params: {
                        'LAYERS': resource.title.replace(/ /g, '_').replace(/\(/g, '').replace(/\)/g, '').replace(/-/g, '_'),
                        'TRANSPARENT': true,
                        ratio: 1
                    },
                    serverType: 'geoserver',
                }),
                renderMode: 'vector'
            });
            mapLayers.push(newLayer);
        });
        this.map.setLayerGroup(new OlLayerGroup({
            'layers': mapLayers
        }));
    }

    activateGeoTag(tag_id: number): void {
        const locationInExistingArr = this.enabledGeoTags.indexOf(tag_id);
        if (locationInExistingArr > -1) {
            this.enabledGeoTags.splice(locationInExistingArr, 1);
        } else {
            this.enabledGeoTags.push(tag_id);
        }
        this.markerSource.clear();
        let index = 0;
        this.enabledGeoTags.forEach((geo_tag_id: Number) => {
            const geoTag: GeoTag = this.apiService.getGeoTagById(Number(geo_tag_id), this.geoTags);
            const iconFeature = new Feature({
                geometry: new Point(transform([geoTag.longitude, geoTag.latitude], 'EPSG:4326', 'EPSG:3857')),
                geoTag: geoTag
            });
            this.markerSource.addFeature(iconFeature);
            index++;
            if (index === this.enabledGeoTags.length) {
                // this.map.getView().setCenter(transform([geoTag.longitude, geoTag.latitude], 'EPSG:4326', 'EPSG:3857'));
                // this.map.getView().setZoom(14);
                this.flyTo(transform([geoTag.longitude, geoTag.latitude], 'EPSG:4326', 'EPSG:3857'), function () { });

            }
        });
    }

    flyTo(location, done) {
        const duration = 2000;
        const zoom = this.map.getView().getZoom();
        let parts = 2;
        let called = false;
        function callback(complete) {
            --parts;
            if (called) {
                return;
            }
            if (parts === 0 || !complete) {
                called = true;
                done(complete);
            }
        }
        this.map.getView().animate({
            center: location,
            duration: duration
        }, callback);
        this.map.getView().animate({
            zoom: zoom - 1,
            duration: duration / 2
        }, {
                zoom: zoom,
                duration: duration / 2
            }, callback);
    }

    ngAfterViewInit() {

        this.apiService.getGeoTagsBasic().subscribe((geoTagList: Array<GeoTag>) => {
            this.geoTags = geoTagList;
            this.route.params.subscribe(url_params => {
                if (typeof url_params.geo_tags !== 'undefined') {
                    url_params.geo_tags.split(',').map((tag_id: string) => {
                        this.activateGeoTag(Number(tag_id));
                    });
                }
            });
        });

        this.apiService.getResourcesBasic().subscribe((resources: Array<Resource>) => {
            this.resources = resources;
            this.route.params.subscribe(url_params => {
                if (typeof url_params.map_layers !== 'undefined') {
                    url_params.map_layers.split(',').map((layer_id: string) => {
                        this.activateMapLayer(Number(layer_id));
                    });
                }
            });
        });

        const resizeEvent = window.document.createEvent('UIEvents');
        resizeEvent.initUIEvent('resize', true, false, window, 0);
        window.dispatchEvent(resizeEvent);

    }

}
