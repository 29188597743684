import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ConfigurationService } from '../services/configuration.service';

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit, AfterViewInit {

    public loaded = false;

    constructor(private configurationService: ConfigurationService) { }

    ngOnInit() {
        this.configurationService.setTitle('Contact us');
    }

    ngAfterViewInit() {
        this.loaded = true;
    }

}
