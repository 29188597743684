import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { MapComponent } from './map/map.component';
import { ResultsComponent } from './results/results.component';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { AppComponent } from './app.component';
import { RoutingModule } from './routing/routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxSmartModalModule } from 'ngx-smart-modal';

import { ConfigurationService } from './services/configuration.service';
import { APIService } from './services/api.service';
import { LayerService } from './services/layer.service';

import { HeaderComponent } from './structure/header/header.component';
import { FooterComponent } from './structure/footer/footer.component';
import { SplashComponent } from './splash/splash.component';
import { ExpoComponent } from './expo/expo.component';
import { CollectionComponent } from './collection/collection.component';
import { HelpComponent } from './help/help.component';
import { GeneologyComponent } from './geneology/geneology.component';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { AcknowledgementsComponent } from './acknowledgements/acknowledgements.component';
import { TermsComponent } from './terms/terms.component';
import { ContactComponent } from './contact/contact.component';
import { EncodeURI } from './pipes/encode-uri.pipe';
import { InfoComponent } from './info/info.component';
import { SearchComponent } from './search/search.component';

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        FooterComponent,
        SplashComponent,
        ExpoComponent,
        CollectionComponent,
        HelpComponent,
        GeneologyComponent,
        SafeHtmlPipe,
        AcknowledgementsComponent,
        TermsComponent,
        ContactComponent,
        EncodeURI,
        InfoComponent,
        ResultsComponent,
        SearchComponent,
        MapComponent,
        SafeUrlPipe
    ],
    imports: [
        RoutingModule,
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        TabsModule.forRoot(),
        LoadingBarHttpClientModule,
        LoadingBarRouterModule,
        CarouselModule.forRoot(),
        NgSelectModule,
        AccordionModule.forRoot(),
        BrowserAnimationsModule,
        NgxSmartModalModule.forRoot()
    ],
    providers: [
        ConfigurationService,
        Title,
        APIService,
        LayerService,
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
