
import { zip as observableZip } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { ConfigurationService } from '../services/configuration.service';
import { APIService } from '../services/api.service';
import { Resource } from '../models/Resource';
import { ResourceType } from '../models/ResourceType';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Tag } from '../models/Tag';
import { NgxSmartModalService } from 'ngx-smart-modal';


@Component({
    selector: 'app-results',
    templateUrl: './results.component.html',
    styleUrls: ['./results.component.scss']
})
export class ResultsComponent implements OnInit {

    public tags: Tag[] = [];
    public resources: Resource[] = [];
    public searchTerms: string[] = [];

    constructor(
        private configurationService: ConfigurationService,
        private apiService: APIService,
        public sanitizer: DomSanitizer,
        private route: ActivatedRoute,
        private ngxSmartModalService: NgxSmartModalService
    ) { }

    public openModal(resource: Resource): void {
        this.ngxSmartModalService.setModalData(resource, 'resourceModal');
        this.ngxSmartModalService.getModal('resourceModal').open();
    }

    public clearModelData() {
        this.ngxSmartModalService.resetModalData('resourceModal');
    }

    public viewerJSUrlCast(url: string): string {
        return url.replace(this.configurationService.api_url, this.configurationService.api_url + '/ViewerJS/index.html#');
    }

    public viewerMicrosfotCast(url: string): string {
        return 'https://view.officeapps.live.com/op/view.aspx?src=' + encodeURIComponent(url);
    }

    public formattedSearchTerms(): string {
        return this.searchTerms.join(', ');
    }
    public countTotalResults(): Number {
        let totalCount = 0;
        this.apiService.getResourceTypes(this.resources).forEach((type: ResourceType) => {
            totalCount += this.apiService.filterResourcesByType(type.title, this.resources).length;
        });

        return totalCount;
    }

    public filterResultsByType(type: string): Resource[] {
        return this.apiService.filterResourcesByType(type, this.resources);
    }

    public getResourceTypes(): ResourceType[] {
        return this.apiService.getResourceTypes(this.resources);
    }

    ngOnInit() {

        this.apiService.getTagsBasic().subscribe((tags: Tag[]) => {
            this.tags = tags;
            this.route.params.subscribe(params => {
                if (typeof params.tag_ids !== 'undefined') {
                    const resource_requests = [];
                    this.searchTerms = params.tag_ids.split(',').map((tag_id: string) => {
                        resource_requests.push(this.apiService.getTagResources(Number(tag_id)));
                        return this.apiService.getTagById(Number(tag_id), this.tags).title;
                    });
                    observableZip(...resource_requests).subscribe((resources: [Resource[]]) => {
                        resources.filter((sub_arr: Resource[]) => {
                            return sub_arr.length > 0;
                        }).map((resource: Resource[]) => {
                            resource.map((arr_resource) => {
                                this.resources.push(arr_resource);
                            });
                        });
                    });
                    this.configurationService.setTitle('Discover more about ' + this.searchTerms.join(', '));
                }
            });
        });

    }

}
