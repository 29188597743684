import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { APIService } from '../services/api.service';
import { Tag } from '../models/Tag';
import { ConfigurationService } from '../services/configuration.service';
import { Collection } from '../models/Collection';

@Component({
  selector: 'app-splash',
  templateUrl: './splash.component.html',
  styleUrls: ['./splash.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SplashComponent implements OnInit {

    public tag: Tag = new Tag();
    public collections: Collection[] = [];
  constructor(
    private apiService: APIService,
    private configurationService: ConfigurationService
  ) { }

  ngOnInit() {
    this.configurationService.setTitle('Welcome');

    this.apiService.getTagsBasic().subscribe((tags: Tag[]) => {
        this.tag = this.apiService.getTagByTerm('projects', 'projects', tags);
        this.apiService.getTagCollections(this.tag.id).subscribe((collections: Collection[]) => {
            this.collections = collections;
        });
    });
  }

}
