import { Page } from './Page';
import { Tag } from './Tag';
import { GeoTag } from './GeoTag';
import { Resource } from './Resource';

export class Collection {
    title = 'Loading';
    description = 'Please wait...';
    resources: Resource[] = [];
    tags: Tag[] = [];
    geoTags: GeoTag[] = [];
    pages: Page[] = [];
}
