export class Task {
    name: string;
    class: string;
    extra: {
        id: number
    };
    textClass: string;
    marriages: [
        {
            spouse: {
                name: string,
                class: string,
                extra: {
                    nickname: string
                }
            },
            children: Task[]
        }
    ];
}
