import { GeoTag } from './../models/GeoTag';
import { Component, OnInit, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { Collection } from '../models/Collection';
import { ConfigurationService } from '../services/configuration.service';
import { APIService } from '../services/api.service';
import { ActivatedRoute } from '@angular/router';
import { ResourceType } from '../models/ResourceType';
import { Resource } from '../models/Resource';
import { Tag } from '../models/Tag';
import { NgxSmartModalService } from 'ngx-smart-modal';

@Component({
  selector: 'app-collection',
  templateUrl: './collection.component.html',
  styleUrls: ['./collection.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CollectionComponent implements OnInit {

    public collection: Collection = new Collection();
    public expanded_types: string[] = [];
    constructor(
      private configurationService: ConfigurationService,
      private apiService: APIService,
      private route: ActivatedRoute,
      private ngxSmartModalService: NgxSmartModalService
    ) { }

    public openModal(resource: Resource): void {
        this.ngxSmartModalService.setModalData(resource, 'resourceModal');
        this.ngxSmartModalService.getModal('resourceModal').open();
    }

    public clearModelData() {
        this.ngxSmartModalService.resetModalData('resourceModal');
    }

    public toggleExpanded(type: string) {
        if (this.expanded_types.includes(type)) {
            this.expanded_types.splice(this.expanded_types.indexOf(type), 1);
        } else {
            this.expanded_types.push(type);
        }
        return false;
    }

    public getDisplayedArr(type: string, arr: Resource[] | GeoTag[] | Tag[]): Resource[] | GeoTag[] | Tag[] {
        return (this.expanded_types.includes(type)) ? arr : arr.slice(0, 4);
    }

    public countTotalResults(): Number {
        let totalCount = 0;
        this.apiService.getResourceTypes(this.collection.resources).forEach((type: ResourceType) => {
            totalCount += this.apiService.filterResourcesByType(type.title, this.collection.resources).length;
        });

        return totalCount;
    }

    public filterResultsByType(type: string): Resource[] {
        return this.apiService.filterResourcesByType(type, this.collection.resources);
    }

    public getResourceTypes(): ResourceType[] {
        return this.apiService.getResourceTypes(this.collection.resources);
    }

    public viewerJSUrlCast(url: string): string {
        return url.replace(this.configurationService.api_url, this.configurationService.api_url + '/ViewerJS/index.html#');
    }

    public viewerMicrosfotCast(url: string): string {
        return 'https://view.officeapps.live.com/op/view.aspx?src=' + encodeURIComponent(url);
    }

    ngOnInit() {
      this.apiService.getCollection(this.route.snapshot.params.collection_id).subscribe((response: Collection) => {
        this.collection = response;
        this.configurationService.setTitle(response.title + ' collection');
      });
    }

}
