import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { ConfigurationService } from '../services/configuration.service';

@Component({
  selector: 'app-acknowledgements',
  templateUrl: './acknowledgements.component.html',
  styleUrls: ['./acknowledgements.component.scss']
})
export class AcknowledgementsComponent implements OnInit, AfterViewInit {

    public loaded = false;

    constructor(private configurationService: ConfigurationService) { }

    ngOnInit() {
      this.configurationService.setTitle('Acknowledgements');
    }

    ngAfterViewInit() {
        this.loaded = true;
    }
}
