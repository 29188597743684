import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ConfigurationService } from '../services/configuration.service';



import { APIService } from '../services/api.service';
import { Tag } from '../models/Tag';
import { Router } from '@angular/router';

@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss']
})

export class SearchComponent implements OnInit {
    @ViewChild('searchTerm') searchTerm: ElementRef;
    public selected: string[] = [];
    public tags: Tag[] = [];
    public searchTerms: String[] = [];
    public searchError: string;

    constructor(
        private configurationService: ConfigurationService,
        private apiService: APIService,
        private router: Router
    ) { }

    ngOnInit() {
        this.configurationService.setTitle('Discover');

        this.apiService.getTagsBasic().subscribe((tagList: Array<Tag>) => {
            this.tags = tagList;
        });

    }

    getSearchTerms(): String[] {
        if (this.searchTerms.length < 1) {
            this.searchTerms = this.tags.filter((tag: Tag) => {
                return tag.title.toLowerCase() !== tag.context.toLowerCase();
            }).map((tag: Tag) => {
                return tag.title + ' : ' + tag.context;
            });
        }

        return this.searchTerms;
    }

    updateSearch() {
        if (this.selected.length > 0) {
            const id_Arr: number[] = [];
            this.searchError = undefined;
            this.selected.forEach((term: string) => {
                id_Arr.push(this.apiService.getTagByTerm(term.split(' : ')[0], term.split(' : ')[1], this.tags).id);
            });
            console.log('this should be searching by now', this.selected, id_Arr.join(','));
            this.router.navigate(['/results/' + id_Arr.join(',')]);
        } else {
            this.searchError = 'Please enter at least one key word';
        }
    }

}
